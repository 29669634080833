document.addEventListener("DOMContentLoaded", () => {
    // Mobile navbar buttons.
    const mobileMenuOpen = document.getElementById("mobile-menu-open");
    const mobileMenuClose = document.getElementById("mobile-menu-close");
    const mobileMenu = document.getElementById("mobile-menu");

    mobileMenuOpen.addEventListener("click", () => {
        mobileMenu.classList.remove("hidden");
    });

    mobileMenuClose.addEventListener("click", () => {
        mobileMenu.classList.add("hidden");
    });

    // When the user clicks in any link, close the menu.
    mobileMenu
        .querySelectorAll("a")
        .forEach((link) =>
            link.addEventListener("click", () =>
                mobileMenu.classList.add("hidden"),
            ),
        );
});
